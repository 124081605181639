import toDecimalPlaces from './chartData/toDecimalPlaces'
import { Units } from '~/constants/general'

export const numberFormat = (value: string | number, digits: number = 0) => {
  if (typeof value !== 'number') return value

  return value.toLocaleString('en-US', {
    maximumFractionDigits: digits,
    useGrouping: true,
  })
}

export const priceFormatter = (price: number, unit: string = '') => {
  const valueWithDecimals = toDecimalPlaces(price, unit || Units.DOLLAR)
  const newValue = numberWithCommas(valueWithDecimals)

  return `${unit}${newValue}`
}

export const absNumberFormatter = (value: number, digits?: number) => {
  return numberFormat(Math.abs(value), digits)
}
